import { ADD_NEW_CREATE_SO_ITEM, ADD_NEW_CREATE_SO_ITEM_BULK, CLEAR_NEW_CREATE_SO_ITEMS, EDIT_NEW_CREATE_SO_ITEM, REMOVE_NEW_CREATE_SO_ITEM } from '../../../stockOrder/data/actions/actionTypes';
import { SET_SELECTED_SUPPLIER, CLEAR_SELECTED_SUPPLIER ,SET_STOCK_ORDER_ITEMS} from '../actions/actionTypes'


import cloneDeep from 'lodash/cloneDeep';

const initialState = {
  selectedSupplier: null as any,
  newStockOrderItems: [],
  stockOrderProducts: []
};

export function stockOrderMobileReducer(state = initialState, action: any) { 


  switch (action.type) {

    case SET_SELECTED_SUPPLIER:
      return {
        ...state,
        selectedSupplier: action.data,

      };

    case SET_STOCK_ORDER_ITEMS:
      return {
        ...state,
        stockOrderProducts : action.data
      }
    case ADD_NEW_CREATE_SO_ITEM:
      // console.log("reducer stuff", action.data)
      return {
        ...state,
        newStockOrderItems : [...state.newStockOrderItems, action.data]
      }
    case ADD_NEW_CREATE_SO_ITEM_BULK:
      console.log("reducer stuff")
      return {
        ...state,
        newStockOrderItems : [...state.newStockOrderItems, ...action.data]
      }
    case EDIT_NEW_CREATE_SO_ITEM:
      return {
        ...state,
        newStockOrderItems : editSOItem(state, action)
      }
    case REMOVE_NEW_CREATE_SO_ITEM:
      return {
        ...state,
        newStockOrderItems : removeSOItem(state, action)
      }
    case CLEAR_NEW_CREATE_SO_ITEMS:
      return {
        ...state,
        newStockOrderItems : []
      }

    case CLEAR_SELECTED_SUPPLIER: return cloneDeep(initialState);
    

    default: return state

  }
}

const editSOItem = (state, action) => {
  const existingIndex = state.newStockOrderItems.findIndex(ns => ns.uuid === action.data.uuid)
  if (existingIndex >= 0) {
    // console.log("ei", existingIndex)
    // console.log("nsoi", state.newStockOrderItems)
    // console.log("actiondata", action.data)
    const edited = [...state.newStockOrderItems]
    edited[existingIndex] = {...action.data}
    return edited
  }
  return state
}

const removeSOItem = (state, action) => {
  const afterRemoval = state.newStockOrderItems.filter(ns => ns.uuid !== action.data.uuid)
  return afterRemoval
}
